<template>
  <div class="business-area-img" @click.stop="handleClick">
    <img :src="icon" />
    <div class="business-area-info">
      <div>{{num}}</div>
      <div class="title">{{title}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
       num:String,
       title:String,
       icon:String
    },
    methods:{
      handleClick(){
        this.$emit('click')
      }
    }
  }
</script>

<style lang="scss">
  .business-area-img{
    position: relative;
    flex:1;
    
    img {
      z-index: 1;
      width: 100%;
    }
    
    .business-area-info{
      position: absolute;
      left: 0px;
      top: 0px;
      padding-top: 9px;
      padding-left: 12px;
      font-size: 11px;
      color: #fff;
      .title{
        margin-top: 7px;
        font-size: 15px;
      }
    }
  }
</style>
