<template>
  <div class="partner-section">
    <div class="partner-wrap">
      <div class="partner-content-wrap">
        <div class="title-wrap">
          <section-title chTitle="合作伙伴" enTitle="Partners "></section-title>
        </div>
        <swiper :options="swiperOption" style="padding-left: 10px;">
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/abbot.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/bostonscientific.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/CDRD.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/EUSTM.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/gfxd.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/green.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/MEDTRONIC.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/merck.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/multiregional.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/norvartis.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/pfzier.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/qs.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/ROCHE.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/sanofiaventis.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/stryker.png" />
          </swiper-slide>
          <swiper-slide class="partner-item">
            <img src="../../image/home/partners/zxyl.png" />
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>



      </div>
    </div>
  </div>
</template>

<script>
  import SectionTitle from '@/components/section-title.vue'
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  export default {
    components: {
      SectionTitle,
      Swiper,
      SwiperSlide
    },
    data() {
      return {
        swiperOption: {
          pagination: {
            el: '.swiper-pagination',
          },
          slidesPerView: 3.36,
          slidesPerGroup: 3,
          spaceBetween: 0,
        }
      }
    }
  }
</script>

<style lang="scss">
  .partner-section {
    padding-top: 42px;

    .partner-wrap {
      position: relative;
      padding-bottom: 60.53%;
      background-image: url(../../image/home/partnersbg.png);
      background-size: 100%;
      background-repeat: no-repeat;
    }

    .partner-content-wrap {
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      height: 100%;

      .title-wrap {
        height: calc(100vw * 0.2213);
        display: flex;
        align-items: center;
      }


      .swiper-pagination {
        position: static;
        box-sizing: border-box;
        margin-top: 7%;

        .swiper-pagination-bullet {
          margin-left: 5px;
          border: 2px solid #fff;
          border-radius: 10px;
          opacity: 1;
          background: unset;

          &:last-child {
            margin-right: 0px;
          }
        }

        .swiper-pagination-bullet-active {
          width: 18px;
          border-radius: 18px;
          background-color: #f36314;
        }
      }

    }

    .partner-item {
      img {
        width: 100%;
      }
    }

  }
</style>
