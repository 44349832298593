import request from '@/utils/request'

/**
 * 获取新闻列表
 * @param {Object} pageNo
 * @param {Object} pageSize
 */
export function getBusinessCategory() {
  return request({
    url: '/cms/api/portal/categories/154/children',
    method: 'get',
    params: {
    }
  })
}


export function getCategoryDetail(categoryId){
  return request({
    url: '/cms/api/portal/articles',
    method: 'get',
    params: {
      categoryId:categoryId,
      currPage:1,
      pageSize:1000
    }
  })
}






