<template>
  <div class="about-wrap">
    <section-title chTitle="关于我们" enTitle="About Us"></section-title>
    <div class="about-us-introduce">
    QUAKY VENTURE GP LTD.（夸克资本）成立于2015年，总部位于加拿大温哥华，是一家专注于健康产业领域创新公司长期价值提升的投资机构。核心业务包括：直接股权投资；打造全球化的健康医疗商业化平；发起和管理医疗健康产业基金。夸克资本拥有独特的高管团队和科学及商业委员会，委员会成员由在科研、转化医学、临床、药企管理、金融投资、全球化战略等方面有着深厚背景的世界级专家组成。

    
    <p>2017年4月，由夸克资本及蔷薇资本有限公司发起成立夸克医药（中国）有限公司，主要依托夸克资本从全球生物医药项目识别体系中，遴选出具有真正突破性和创新性的技术项目在中国实现产业化落地。</p>

      <div class="learn-more-btn" @click.stop="$router.push({path:'/about'})">了解更多</div>
    </div>
  </div>
</template>

<script>
  import SectionTitle from '@/components/section-title.vue'
  export default {
    name: 'Home',
    components: {
      SectionTitle,
    },
    data() {
      return {
        businessAreaImg: []
      }
    }
  }
</script>

<style lang="scss">
  .about-wrap {
    padding-top: 46px;

    .about-us-introduce {
      padding-top: 9px;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 13px;
      color: #535353;
      line-height: 20px;
    }


  }
</style>
