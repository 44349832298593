<template>
  <div class="home-page">
    <div class="banner-wrap">
      <img src="@/image/home/banner.png" />
    </div>
    <div class="content-body">
      <!--业务领域-->
      <business-area></business-area>
      <!--关于我们-->
      <about></about>
      <!--新闻动态-->
      <news></news>
      <!--合作伙伴-->
      <!-- <cooperative-partner></cooperative-partner> -->
      <!--关于我们-->
      <contact-us></contact-us>
    </div>
  </div>
</template>

<script>
  import NavBar from '@/components/navbar.vue'
  import BusinessArea from './BusinessArea.vue'
  import About from './About.vue'
  import News from './News.vue'
  import CooperativePartner from './CooperativePartner.vue'
  import ContactUs from './ContactUs.vue'
  
  import {initLocale} from '@/utils/locale'
    
  //初始化语言
  initLocale()
  
  
  export default {
    name: 'Home',
    components: {
      NavBar,
      BusinessArea,
      About,
      News,
      CooperativePartner,
      ContactUs
    }
  }
</script>

<style lang="scss">
 .home-page{
  
 }
 .content-body{
   background-image: url(../../image/home/deco.png);
   background-size: 100%;
   background-repeat: no-repeat;
   padding-bottom: 30px;
 }
 .business-area{
   padding-top: 32px;
   .introduce{
     font-size: 12px;
     color:#959595;
     padding-left: 12px;
     padding-top: 12px;
     padding-bottom: 19px;
   }
 }
</style>
