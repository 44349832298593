<template>
  <div class="business-area">
    <section-title chTitle="业务领域" enTitle="Business Areas"></section-title>
    <div class="introduce">夸克医药（中国）有限公司中国中心共有七大细分领域</div>
    <div class="business-img-wrap">
      <business-area-img @click="handleBusinessItemClick(item)" :key="index" class="item" v-for="(item,index) in businessAreaList" :num="getNum(index)" :icon="getBusinessImg(item.id)" :title="item.name"></business-area-img>
    </div>
  </div>
</template>

<script>
  import SectionTitle from '@/components/section-title.vue'
  import BusinessAreaImg from '@/components/business-area-img.vue'
  import {
    getBusinessCategory,
  } from '@/api/business.js'
  
  export default {
    name: 'Home',
    components: {
      SectionTitle,
      BusinessAreaImg
    },
    data(){
      return {
       businessImg:{
         '155':require('@/image/home/business-area/biomedicine.png'),
         '156':require('@/image/home/business-area/medical-equipment.png'),
         '157':require('@/image/home/business-area/diagnostic-test.png'),
         '158':require('@/image/home/business-area/ai.png'),
         '160':require('@/image/home/business-area/drug-delivery.png'),
         '161':require('@/image/home/business-area/cell-therapy.png'),
         '162':require('@/image/home/business-area/bioengineering-system.png')
       },
       businessAreaList:[]
      }
    },
    created() {
      this.getBusinessAreas()
    },
    computed:{
     
    },
    methods:{
      getBusinessImg(categoryId){
        return this.businessImg[''+categoryId]
      },
      getNum(index){
        const num = index + 1
        return num > 9 ? '' + num : '0'+num
      },
      getBusinessAreas(){
        getBusinessCategory().then(data => {
          this.businessAreaList = data.data
        })
      },
      handleBusinessItemClick(item){
        this.$router.push({path:'/business',query:{categoryId:item.id}})
      }
    }
  }
</script>

<style lang="scss">
 .business-area{
   padding-top: 32px;
   .introduce{
     font-size: 12px;
     color:#959595;
     padding-left: 12px;
     padding-top: 12px;
     padding-bottom: 19px;
   }
   .business-img-wrap{
     display: flex;
     flex-wrap: wrap;
     margin-bottom: 2px;
     
     .item{
       margin-right: 2px;
       min-width: 45%;
       &:last-child{
         margin-right: 0px;
       }
     }
     
   }
 }
</style>
