function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}

function initLocale() {
  var locale = getQueryString('locale')
  if (!locale) {
    locale = (navigator.language || navigator.browserLanguage).toLowerCase()
  }

  var isChinesePath = !window.location.pathname.startsWith("/en")
  var isChineseLocale = (locale === 'zh-cn') || (locale === 'zh-tw')


  if (isChineseLocale && !isChinesePath) {
    //改为中文路径访问
    window.location.href = '/'
  } else if (!isChineseLocale && isChinesePath) {
    window.location.href = '/en/'
  }
}

export {
  getQueryString,
  initLocale
}
