<template>
  <div class="news-wrap">
    <section-title chTitle="新闻动态" enTitle="News"></section-title>
    <div class="news-container">
      <div class="news-num-wrap">
        <span class="num" :key="index" :class="{active:activeIndex===index}" v-for="(item,index) in newsList">{{index+1>9?(index+1):'0'+(index+1)}}</span>
      </div>
      <div class="news-img-wrap">
        <swiper class="swiper-wrap" ref="newsSwiper" :options="swiperOption">
          <swiper-slide :key="index"  class="swipe-item" v-for="(item,index) in newsList">
            <div class="news-image" @click.stop="$router.push({path:'/news-detail',query:{newsId:item.id}})" :style="{backgroundImage: 'url(' + item.titleImgUrl + ')' }">
              <div class="news-image-placeholder"></div>
            </div>
            <div class="news-info" @click.stop="$router.push({path:'/news-detail',query:{newsId:item.id}})">
              <div class="date">{{item.publishTime}}</div>
              <div class="title">{{item.cmsTitle}}</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="more-btn-wrap">
        <div class="learn-more-btn" @click.stop="$router.push({path:'/news'})">了解更多</div>
      </div>
    </div>
  </div>
</template>

<script>
  import SectionTitle from '@/components/section-title.vue'
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  import {
    getNews
  } from '@/api/news.js'

  export default {
    components: {
      SectionTitle,
      Swiper,
      SwiperSlide
    },
    data() {
      const self = this
      return {
        newsList: [],
        activeIndex: 0,
        swiperOption: {
          autoplay: {
            disableOnInteraction: false,
            delay: 3000,
          },
          on: {
            slideChangeTransitionEnd: () => {
              const activeIndex = self.$refs.newsSwiper.$swiper.activeIndex
              self.activeIndex = activeIndex
              
            }
          }
        }
      }
    },
    created() {
      this.initData()
    },
    methods: {
      initData() {
        getNews(1, 3).then(data => {
          this.newsList = data.page.list
        })
      }
    }
  }
</script>

<style lang="scss">
  .news-wrap {
    padding-top: 46px;

    .news-container {
      padding-top: 15px;
      padding-left: 12px;
      padding-right: 12px;

      .more-btn-wrap {
        margin-top: 20px;
      }
    }

    .news-num-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a5a5a5;

      .num {
        font-size: 14px;
        font-weight: bold;
        color: a5a5a5;
        font-style: italic;
        margin-right: 24px;

        &.active {
          color: #535353;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .news-img-wrap {
      padding-top: 14px;

      .news-image {
        background-repeat: no-repeat;
        background-size: cover;

        .news-image-placeholder {
          height: 0px;
          padding-bottom: 59.143%;
        }
      }

      .news-info {
        padding-top: 14px;

        .date {
          font-size: 11px;
          color: #949494;
        }

        .title {
          padding-top: 5px;
          font-size: 14px;
          color: #535353;
          line-height: 20px;
          word-wrap: break-word;
        }
      }

    }

  }
</style>
