<template>
  <div class="contact-us-section">
    <section-title chTitle="联系我们" enTitle="Contact Us"></section-title>
    <div class="map-wrap">
      <div id="mapConatiner"></div>
      <div class="address-info"><i class="icon address"></i><span>成都高新区天府三街199号太平洋金融大厦A区17层</span></div>
      <div class="contact-info">
        <div style="display: flex;flex: 1;align-items: center;">
          <i class="icon phone"></i><span>028-86528099-800</span>
        </div>
        <div style="display: flex;flex: 1;align-items: center;">
          <i class="icon email"></i><span>gdcc_china@gdcccn.com</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SectionTitle from '@/components/section-title.vue'

  export default {
    components: {
      SectionTitle
    },
    data() {
      return {}
    },
    methods: {
      initMap: function() {
        var map = new BMap.Map("mapConatiner");
        map.addControl(new BMap.NavigationControl());
        map.addControl(new BMap.ScaleControl());
        map.addControl(new BMap.OverviewMapControl());
        map.addControl(new BMap.MapTypeControl({
          mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP]
        }));
        var point = new BMap.Point(104.069449, 30.551921);
        var marker = new BMap.Marker(point);
        map.addOverlay(marker);
        var opts = {
          width: 150, // 信息窗口宽度
          height: 100, // 信息窗口高度
          title: "夸克医药（中国）有限公司", // 信息窗口标题
          enableMessage: true, //设置允许信息窗发送短息
        }
        var msg = '成都高新区天府三街199号太平洋金融大厦A区17层';
        var infoWindow = new BMap.InfoWindow(msg, opts); // 创建信息窗口对象
        marker.addEventListener("click", function() {
          map.openInfoWindow(infoWindow, point); //开启信息窗口
        });
        map.centerAndZoom(point, 16);
        map.enableScrollWheelZoom(true);
      }
    },
    mounted() {
      this.initMap()
    }
  }
</script>

<style lang="scss">
  .contact-us-section {
    padding-top: 45px;

    .map-wrap {
      padding-top: 24px;
    }

    #mapConatiner {
      height: 218px;
    }

    .icon {
      display: inline-block;
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
      background-size: 15px 15px;
    }

    .address {
      background-image: url(../../image/location.png);
    }

    .email {
      // margin-left: 40px;
      background-image: url(../../image/email.png);
    }

    .phone {
      background-image: url(../../image/phone.png);
    }

    .address-info,
    .contact-info {
      padding-left: 13px;
      margin-top: 15px;
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 13px;
      color: #535353;

      span {
        padding-left: 3px;
      }
    }

    .contact-info {
      margin-top: 0px;
    }
    
  }
</style>
